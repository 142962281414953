<template>
  <div class="green"></div>

  <section class="section card-component-turmas">
    <div class="card-img-turmas">
      <img src="../assets/futebol-sem-fundo.png" alt="" />
    </div>
    <div class="card-text-turmas">
      <h2>Aulas de futebol</h2>
      <p>Trabalhando em equipe, estratégia e diversão.</p>
    </div>
  </section>

  <section class="section card-component-turmas ballet-cell">
    <div class="card-text-turmas">
      <h2>Aulas de Ballet</h2>
      <p>Dançando com o coração, construindo sonhos com os pés.</p>
    </div>
    <div class="card-img-turmas">
      <img src="../assets/ballet-sem-fundo.png" alt="" />
    </div>
  </section>

  <section class="section card-component-turmas">
    <div class="card-img-turmas">
      <img src="../assets/judo-sem-fundo.png" alt="" />
    </div>
    <div class="card-text-turmas">
      <h2>Aulas de Judô</h2>
      <p>Aprendendo a arte do Judô com técnica e respeito.</p>
    </div>
  </section>

  <div class="material" >
  <a
    class="botao-entrar-em-contato-extra"
    href="https://wa.me/5511972216682"
    target="_blank"
    ><button class="btn-agendar">
      Conheça mais sobre nossa escola
      <font-awesome-icon :icon="['fab', 'whatsapp']" /></button
  ></a>
</div>

  <div class="material">
    <div class="card-container">
      <div class="card">
        <img src="../assets/passeio.jpg" alt="Alfabetização" />
        <h3><span class="title-strong">Alfabetização, literacia e numeracia</span></h3>
        <p>
          Exploramos novos ambientes para enriquecer o aprendizado, proporcionando
          experiências culturais, científicas e sociais que ampliam a visão de mundo das
          crianças.
        </p>
      </div>
      <div class="card">
        <img src="../assets/baby-fotos/baby (17).jpeg" />
        <h3><span class="title-strong">Festas tamáticas</span></h3>
        <p>
          Momentos especiais que celebram a infância por meio de festas lúdicas e
          educativas, incentivando a criatividade, a socialização e o senso de
          pertencimento.
        </p>
      </div>
      <div class="card">
        <img src="../assets/atv-pintura.jpeg" />
        <h3><span class="title-strong">Atividades especiais</span></h3>
        <p>
          Desenvolvemos atividades diferenciadas que estimulam a imaginação, a expressão e
          as habilidades motoras, sempre alinhadas ao desenvolvimento integral das
          crianças.
        </p>
      </div>
    </div>
  </div>

  <div class="card-email">
    <EmailComponent />
  </div>
</template>

<script>
import EmailComponent from "./emailComponent.vue";
import "swiper/css";
import { Navigation, Autoplay, Pagination } from "swiper/modules";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "EnsinoFundamentalI",
  components: {
    EmailComponent,
  },
  data() {
    return {
      horariosBercario: [
        { periodo: "Tarde", horario: "13:00 às 17:30" },
        { periodo: "Integral", horario: "8:00 ás 18:00" },
        { periodo: "-", horario: "-" },
        { periodo: "Futebol", horario: "17:30 ás 18:30 - (Quarta-feira)" },
        { periodo: "Ballet", horario: "17:30 ás 18:30 - (Segunda-feira)" },
        { periodo: "Judô", horario: "17:40 ás 18:30 - (Quinta-feira)" },
      ],
      images: [
        { src: require("../assets/livro01-fundamental.png"), alt: "Imagem 1" },
        { src: require("../assets/livro02-fundamental.png"), alt: "Imagem 2" },
        { src: require("../assets/livro03-fundamental.png"), alt: "Imagem 3" },
        { src: require("../assets/livro04-fundamental.png"), alt: "Imagem 4" },
        { src: require("../assets/livro05-fundamental.png"), alt: "Imagem 5" },
        { src: require("../assets/livro06-fundamental.png"), alt: "Imagem 6" },
        { src: require("../assets/livro07-fundamental.png"), alt: "Imagem 7" },
      ],
    };
  },
  setup() {
    return {
      modules: [Autoplay, Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.card-text-turmas p {
  font-size: 14px;
}

.bilingue {
  background-color: var(--azul);
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bilingue h2 {
  color: var(--yellow);
  font-family: var(--baloo);
  font-weight: 800;
  font-size: 42px;
}

.bilingue h3 {
  color: var();
  font-family: var(--baloo);
  font-weight: 800;
  font-size: 30px;
}

.card-bilingue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  gap: 1rem;
}

.card-email {
  padding: 3rem 0;
}

.bilingue-first {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 56%;
}

.bilingue-second {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.bilingue-second img {
  width: 100%;
}

.text-section {
  max-width: 50%;
}

.text-section p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.section {
  padding: 20px !important;
}

.card-material-fundamental {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.card-component-turmas {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.card-text-turmas {
  width: 50%;
}

.card-img-turmas {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  border: none !important;
}

.card-img-turmas img {
  border: none !important;
}

.card-swiper-slide {
  width: 40%;
  height: auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.card-swiper-slide img {
  display: block;
  width: 100%;
  height: 500px;
}

.book-gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
}

.book-gallery img {
  width: 130px;
  height: auto;
  transition: transform 0.3s ease;
}

.book-gallery img:hover {
  transform: scale(1.05);
}

.image-section {
  width: 30% !important;
  flex: 1;
}

.card-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
}

.image-section img {
  width: 80%;
  height: auto;
  border-radius: 10px;
}

.text-section {
  flex: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-section p {
  font-size: 18px;
  line-height: 1.6;
}

.content-section {
  padding: 20px;
  margin-top: 3rem;
}

.content-wrapper,
.content-wrapper-metodo-fonico {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.image-section {
  flex: 1;
}

.text-section {
  flex: 2;
}

.text-section h4 span {
  font-size: 30px;
}

.literatura-viva {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
  margin: 2rem 0 0 0;
}

.card-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
}

.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 30%;
  text-align: left;
}

.card img {
  width: 100%;
  height: 420px;
  object-fit: cover;
}

.card h3 {
  color: var(--orange);
  margin: 15px;
  font-size: 20px;
  font-weight: bold;
}

.card p {
  color: #555;
  margin: 0 15px 15px;
  font-size: 16px;
}

.card .title-strong {
  color: var(--orange);
  font-weight: bold;
  font-size: 28px;
}

.literatura-viva p {
  width: 80%;
  display: flex;
  text-align: center;
  align-items: center !important;
  justify-content: center;
}

.book-gallery-responsive {
  display: none;
}

.literatura-viva img {
  width: 800px;
  height: auto;
}

.botao-entrar-em-contato-extra {
  background-color: var(--color-white);
  border: 1px solid var(--color-white);
  border-radius: 6rem;
  text-decoration: none;
  color: var(--color-black-clean);
  padding: 0.4rem;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 947px) {
  .text-section p {
    font-size: 14px;
    line-height: 1.6;
    width: 100%;
  }

  .text-section {
    max-width: 84%;
  }

  .literatura-viva p {
    width: 100%;
    font-size: 14px;
  }

  .content-wrapper-metodo-fonico {
    display: flex;
    flex-direction: column-reverse;
  }

  .image-section {
    flex: 1;
    width: 100% !important;
  }

  .image-section img {
    width: 80%;
    height: auto;
    border-radius: 10px;
  }

  .card-material-fundamental {
    width: 100% !important;
  }

  .botao-entrar-em-contato-extra {
    font-size: 14px !important;
    margin: 0 0 1rem 0;
  }

  .content-wrapper-metodo-fonico {
    width: 100% !important;
  }
  .content-section {
    padding: 20px;
    margin-top: 1rem;
  }

  .book-gallery {
    display: none;
  }

  .book-gallery-responsive {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
  }

  .swiper-slide {
    width: 25% !important;
  }

  .swiper-slide img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
  }
  .swiper-pagination-bullet {
    background-color: black;
    opacity: 1; /* Certifique-se de que a opacidade esteja visível */
    width: 10px;
    height: 10px;
    margin: 0 5px;
  }

  .swiper-pagination-bullet-active {
    background-color: red; /* Cor do bullet ativo */
  }

  .card-img-turmas {
    padding: 0 !important;
  }

  .ballet-cell {
    flex-direction: column-reverse !important;
  }
}
</style>
