<template>
  <div class="green"></div>

  <div class="banner">
    <img src="../assets/2.png" alt="" />
  </div>

  <section class="section card-component-turmas">
    <div class="card-text-turmas">
      <h2>Berçário</h2>
      <p>
        A fase de 1 a 2 anos é importante para o desenvolvimento físico, cognitivo e
        emocional do bebê. Período de descobertas e momento em que o bebê aprende a
        associar a sua existência ao mundo.<br />
        <br />
        Oferecemos acolhimento, atendimento diferenciado, segurança e cuidados ao seu bebê
        através de ambiente projetado exclusivamente para essa fase do seu filho.
      </p>
      <a href="https://wa.me/5511972216682" target="_blank"
        ><button class="btn-agendar">
          Agende uma visita <font-awesome-icon :icon="['fab', 'whatsapp']" /></button
      ></a>
    </div>
    <div class="card-img-turmas">
      <swiper :navigation="true" :pagination="true" :modules="modules" class="mySwiper">
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (9).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby.jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (1).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img
            class="img-carrousel-estruture"
            src="../assets/baby-fotos/baby (3) mobile.jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (6).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (4).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (5).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (8).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (1,5).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (11).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (10).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (12).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (19).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (13).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (14).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (15).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (16).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (17).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (18).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (7).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (20).jpeg"
        /></swiper-slide>
        <swiper-slide class="card-swiper-slide"
          ><img class="img-carrousel-estruture" src="../assets/baby-fotos/baby (21).jpeg"
        /></swiper-slide>
      </swiper>
    </div>
  </section>

  <section class="section bilingue">
    <div class="card-bilingue">
      <div class="bilingue-first">
        <h2>Educação Bilíngue</h2>
        <p>
          Na Escola Árvore da Vida, acreditamos que a educação bilíngue é essencial. A
          fluência em línguas estrangeiras abre portas para novas culturas e amplia a
          comunicação global, essencial no mundo de hoje.
        </p>
        <h3>Língua Estrangeira na Primeira Infância</h3>
        <p>
          Introduzir uma língua estrangeira cedo facilita o aprendizado de novos idiomas.
          Aprender inglês pode ser fácil e divertido quando ensinado de forma lúdica, com
          brincadeiras, histórias, jogos e músicas, permitindo uma assimilação natural.
          Pesquisas mostram que, até os 10 anos, a aquisição de idiomas é mais fluida, e
          quanto mais cedo a exposição, maior a fluência. O cérebro infantil, moldado para
          captar sons e ritmos, expande sua capacidade neural, favorecendo o aprendizado
          futuro de outras línguas.
        </p>
        <p>
          Oferecemos aulas diárias de inglês, combinando diversão e aprendizado. Nossa
          abordagem lúdica inclui músicas, histórias e brincadeiras, tornando o
          aprendizado envolvente e prazeroso.
        </p>
      </div>
      <div class="bilingue-second">
        <img src="../assets/bilingue.png" alt="" />
      </div>
    </div>
  </section>

  <TableComponent :horarios="horariosBercario" />

  <EmailComponent />
</template>

<script>
import EmailComponent from "./emailComponent.vue";
import TableComponent from "./TableComponent.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { Navigation, Autoplay, Pagination } from "swiper/modules";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "babyComponent",
  components: {
    EmailComponent,
    TableComponent,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      horariosBercario: [
        { periodo: "Meio período", horario: "13h às 17h" },
        { periodo: "Integral", horario: "8h ás 18h" },
      ],
    };
  },
  setup() {
    return {
      modules: [Autoplay, Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.bilingue {
  background-color: var(--azul);
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bilingue h2 {
  color: var(--yellow);
  font-family: var(--baloo);
  font-weight: 800;
  font-size: 42px;
}

.bilingue h3 {
  color: var();
  font-family: var(--baloo);
  font-weight: 800;
  font-size: 30px;
}

.card-bilingue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  gap: 1rem;
}

.bilingue-first {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 56%;
}

.bilingue-second {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.bilingue-second img {
  width: 100%;
}

.card-component-turmas {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 0 100px 0;
}

.card-text-turmas {
  width: 50%;
}

.card-img-turmas {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 0 0;
}

.card-swiper-slide {
  width: 40%;
  height: auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.card-swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
  border: none !important;
}

.card-img-turmas img {
  border-radius: 8px;
}
</style>
